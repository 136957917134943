#layout-title img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  margin-top: -5px;
}

#layout-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.content-layout {
  display: flex;
  flex-direction: row;
}

.icon-bars-menu {
  display: none;
  position: absolute;
  z-index: 999;
  top: 4px;
  left: 40px;
  color: white;
  font-size: 20px;
  cursor: pointer;
}
.icon-bars-menu i {
  font-size: 31px;
}

.icon-back-menu {
  position: absolute;
  z-index: 999;
  top: 4px;
  left: 40px;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.icon-back-menu i {
  font-size: 31px;
}

.iconNotification,
.wallet {
  position: absolute;
  top: 5px;
  right: 35px;
}

.wallet {
  font-size: 18px;
  right: 20px;
}
.layout-nav-menu {
  width: 20%;
  height: 100vh;
  border-right: 1px solid #111;
}

.content-layout-child {
  width: 80%;
  position: relative;
}

.child-component {
  padding-inline: 200px;
  padding-top: 50px;
}

.mobile-nav-menu-content {
  background-color: rgb(255 252 252 / 4%);
  backdrop-filter: blur(2px);
  flex: 1;
  display: none;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.mobile-nav-menu {
  background-color: #1c1e23;
  border-right: 1px solid #0000004d;
  width: 50%;
  height: 100vh;
  position: absolute;
  left: -100%;
  transition: left 0.4s ease-in-out;
}

.mobile-nav-menu-content.active {
  background-color: rgba(255, 252, 252, 0.04);
  opacity: 1;
  z-index: 999;
}

.mobile-nav-menu-content.active .mobile-nav-menu {
  left: 0;
}

.bartop {
  position: absolute;
  top: 0px;
  z-index: 999;
  left: 0;
  padding-block: 20px;
  background-color: var(--background-color);
  width: 100%;
}

@media (max-width: 1200px) {
  .child-component {
    padding-inline: 20px;
  }

  .layout-nav-menu {
    width: 25%;
  }

  .content-layout-child {
    width: 75%;
  }
}

@media (max-width: 890px) {
  .child-component {
    padding-inline: 10px;
  }

  .layout-nav-menu {
    width: 35%;
  }

  .content-layout-child {
    width: 65%;
  }
}

@media (max-width: 670px) {
  .mobile-nav-menu-content {
    display: block;
  }
  #hero h4 {
    font-size: 50px;
  }
  .child-component {
    padding-inline: 10px;
  }

  .layout-nav-menu {
    width: 0%;
    display: none;
  }

  .content-layout-child {
    width: 100%;
  }
}

@media (max-width: 445px) {
  .mobile-nav-menu {
    width: 65%;
  }
}
