.content-telegram {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.content-telegram #telegram-login {
  cursor: pointer;

  position: absolute;
  width: 100%;

  top: -35px;
  left: 6px;
}
